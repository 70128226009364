.footer {
  min-height: 270px;
  margin-top: 20px;
  height: 270px;
  width: 100%;
  color: #006079;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #12232e !important;
}

.footer .nav-link {
  font-size: 14px;
}

.btn {
  background-color: #4da8da !important;
  color: #fff !important;
}

.btn-primary {
  border: #36c3d4 !important;
}
