html,
body {
  height: 100%;
  margin: 0;
}

.site-container {
  min-height: calc(100vh - 270px);
}

h1,
h2,
h3,
h4,
h5 {
  color: #12232e !important;
  font-weight: bold;
}
