.site-header {
  background-color: #12232e !important;
  color: #eefbfb !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.navbar-light .navbar-nav a.nav-link {
  font-size: 18px;
  color: #eefbfb !important;
}

.navbar-light .navbar-nav a.nav-link:hover {
  font-size: 18px;
  color: #4da8da !important;
}

.cart-counter {
  background-color: #36c3d4 !important;
}

.auth-icon {
  color: #fff !important;
  cursor: pointer;
}
